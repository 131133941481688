<template>
  <CRow v-if="oilColor">
    <CCol>
      <div class="card" >
        <div class="card-body pb-0 d-flex justify-content-between">
          <div>
            <div class="text-value-lg">
              <BBadge :style="{backgroundColor: oilColor, color: 'black'}">{{oilName}}</BBadge>
              {{ trans(store.ovm,'v',volUnit) }}
              <BBadge>{{ unitSign(volUnit)}}</BBadge>
              <BBadge :variant="percentVariant(store.pct)" class="ml-2 mr-1">{{ store.pct }} %</BBadge>
            </div>

          </div>
          <slot>
            <CDropdown
              color="transparent p-0"
              placement="bottom-end"
            >
              <template #toggler-content>
                <BIconGearFill variant="info"/>
              </template>
              <CDropdownItem @click="volUnit='l'">Liter</CDropdownItem>
              <CDropdownItem @click="volUnit='g'" >Gallon</CDropdownItem>
              <CDropdownItem @click="volUnit='d'">Drum</CDropdownItem>
            </CDropdown>
          </slot>
        </div>
        <slot name="footer">
          <CChartLine
            :datasets="oilDataSet"
            :options="graphOptions"
            :labels="labels"
          />

<!--          <CChartLineSimple
            class="mt-3"
            style="max-height:200px"
            :background-color="oilColor"
            :data-points="oilData"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            :point-hover-background-color="oilColor"
            :label="oilName"
            :labels="labels"
            :tickMax="store.max"
            :pointed="false"
          />-->
        </slot>
      </div>


      <!--
      <CWidgetDropdown
        :header="`${store.ovm}`"
        :text="`${oilName} 충족률 ${store.pct} %`"
      >
        <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Liter</CDropdownItem>
            <CDropdownItem>Drum</CDropdownItem>
            <CDropdownItem>Gallon</CDropdownItem>
          </CDropdown>
        </template>
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            :background-color="oilColor"
            :data-points="oilData"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            :point-hover-background-color="oilColor"
            :label="oilName"
            :labels="labels"
          />
        </template>
      </CWidgetDropdown>
      -->
    </CCol>

  </CRow>
</template>

<script>
// import { CChartLineSimple } from '../charts'
import { CChartLine } from '@coreui/vue-chartjs'
import { trans, unitSign } from '../../../common/utils'
import {getColor} from "@coreui/utils/src";

export default {
  name: 'WidgetsOils',
  components: { CChartLine },
  props:{
    oilCode: { type: String, default: ''},
    oilName: { type: String, default: ''},
    oilColor: { type: String, default: '#FFFFFF'},
    store: { type: Object, default: null },
    oilData: { type: Array, default: null },
    labels: { type: Array, default: null },

  },
  data () {
    return {
      volUnit: this.$store.state.volUnit,
      trans: trans,
      unitSign: unitSign,
      oilDataSet: {},
      graphOptions: {},

    }
  },
  created(){
    // console.log( '[[[[[[[[[[[[[[ oilColor --------->', this.oilColor );
    // console.log( '[[[[[[[[[[[[[[ store.max -------->', this.store.max)

    this.oilDataSet = [
      {
        data: this.oilData,
        borderColor: '#ffffff',
        backgroundColor: this.oilColor,
        pointBackgroundColor: this.oilColor,
        pointHoverBackgroundColor: this.oilColor,
        label: this.label
      }
    ]

    this.graphOptions = {
      scales: {
        xAxes: [{
          display: false
        }],
        yAxes: [{
          display: false,
          ticks: {
            max: this.store.max
          }
        }]
      },
      elements: {
        line: {
          borderWidth: 2
        },
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4
        }
      },
      maintainAspectRatio: false,
      legend: {
        display: false
      }
    }
  }
 ,
 methods: {
   percentVariant(pct){
     if(pct < 10) return 'warning';
     if(pct > 90) return 'danger';
     return 'info';
   }
 }
}
</script>
