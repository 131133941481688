<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <BRow v-if="oilStore && oilHist && oilCodeMap">
      <BCol md="4" v-for="ocd in oilCodes" :key="ocd">
        <WidgetOils  :oilCode="ocd"
                    :oilName="oilCodeMap[ocd]"
                    :oilColor="oilColors[ocd]"
                    :store="oilStore[ocd]"
                    :oilData="oilHist[ocd].data"
                    :labels="oilHist[ocd].label"
        />
      </BCol>
    </BRow>

    <BRow  class="pre-scrollable mh-100" v-if="isReady">
      <BCol xl="12" v-for="area in areaCodes" :key="area.code">

        <BCard style="min-width:1840px;max-width: 1900px">
          <div slot="header">
            <BButton class="ml-1 font-weight-bolder"
                     variant="outline-warning"
                     size="sm"
                     @click="gotoMonitor(area.arCode)"
            >
              <BIconBack/> {{area.name}} 지역 모니터링
            </BButton>

            <div class="card-header-actions text-muted">
              <BBadge variant="info"> {{area.name}} </BBadge>  {{area.description}}
            </div>
          </div>


          <BRow v-if="isTankReady">
            <BCol xl="4" v-for="tank in area.tanks" :key="tank.tid" v-show="tank.display">

              <BCard style="min-width:600px">
                <div slot="header">
                  <BButtonGroup size="sm" class="small">
                    <BButton variant="outline-info"
                             size="sm"
                             @click="gotoMonitor(area.arCode, tank.tid)">
                      <BIconBack/> <B>{{tank.name}}</B>
                    </BButton>
                    <BButton class="ml-1" :style="{color: '#000000', backgroundColor: oilColors[tank.oilCode]}">
                      <strong>{{oilCodeMap[tank.oilCode]}}</strong>
                    </BButton>
                    <BButton class="ml-1" :variant="tankState[tank.tid].variant">
                      <strong>{{tankState[tank.tid].text}}</strong>
                    </BButton>
                    <BButton class="ml-1" :variant="tankState[tank.tid].pstsVariant" :class="{blink: tankState[tank.tid].psts.code!=='0000'}">
                      {{tankState[tank.tid].psts.message}}
                    </BButton>
                  </BButtonGroup>

                  <div class="card-header-actions">
                    <WidgetEvents class="float-right" :events="events[tank.tid]" :key="widgetKey"/>
                  </div>
                </div>

                <BRow class="mb-1" :key="progressKey">
                  <BCol cols="7">
                    <BProgress :max="100" height="2rem" show-value>
                      <BProgressBar :value="volumes[tank.tid].tvp"
                                    :animated="tankState[tank.tid].sts!=='N'"
                                    :variant="progressVariant(volumes[tank.tid].tvp)"/>
                    </BProgress>
                  </BCol>
                  <BCol cols="5" class="text-right">
                    <BButtonGroup size="sm">
                      <BButton variant="info">
                        <strong>{{ trans(volumes[tank.tid].ovm, 'v', tank.unitOfVol ) }}</strong>
                      </BButton>
                      <BButton variant="outline-info" class="small text-nowrap">
                        {{ trans(volumes[tank.tid].real, 'v', tank.unitOfVol) }} <BBadge>{{unitSign(tank.unitOfVol)}}</BBadge>
                      </BButton>
                    </BButtonGroup>

                  </BCol>
                </BRow>

                <BRow>
                  <BCol>

                    <TankLineChart style="min-height: 250px;"
                                   :tank="tank"
                                   :tankData="tankHist[tank.tid]"
                                   :oilColor="oilColors[tank.oilCode]"
                                   :key="tankChartKey[tank.tid]"

                    />

                  </BCol>
                </BRow>




              </BCard>


            </BCol>
          </BRow>

        </BCard>

      </BCol>
    </BRow>

  <!--
    <p>This view file path: src/views/dashboard</p>
    <p>
      <span class="mr-1" target="_blank">Check</span>
      <a href="https://coreui.io/vue/docs">CoreUI Vue Documentation</a>
    </p>
    -->
  </div>
</template>

<script>
import '@/common/HelperMixin';
import WidgetOils from './components/widget/WidgetOils'
import WidgetEvents from './components/widget/WidgetEvents'
import TankLineChart from './components/charts/TankLineChart'
import {
  apiCall, modalSuccess, modalWarn, random, setAreas, setTanks, sleep, speech, trans, unitSign
} from '@/common/utils';
import {IoStsMap} from "@/common/constants";

export default {
  name: 'Dashboard',
  components: {
    WidgetOils,
    WidgetEvents,
    TankLineChart
  },
  computed: {

  },
  data () {
    return {
      isReady: false,
      progressKey: random(0,10000),
      widgetKey: random(20000,30000),
      isOilReady: false,
      isTankReady: false,
      oilStore: null,
      oilHist: null,
      oilCodes: [],
      oilColors: null,
      oilCodeMap: null,
      areaCodes: null,
      tanks: null,
      tankHist: [],
      eventsKey: 0,
      tankChartKey: {},
      tankState: {},
      tankStateWatch: {tid:'', code: '', message: ''},
      volumes: {},
      events: {},
      volUnit: 'g', // l <--> g
      lenUnit: 'i', // mm <--> i
      tmpUnit: 'f', // c <---> f
      trans: trans,
      unitSign: unitSign,
    }
  },

  watch: {
    'tankStateWatch': {
      handler: function(newVal, oldVal) {
        const tank = this.tanks[newVal.tid];
        // console.log("=============> tanksStateWatch------------->", newVal, oldVal, tank);
        if(tank.display) speech(`${tank.name} 탱크, ${newVal.message} `);
      },
      deep: true
    }
  },

  async created(){
    console.log("------------------------- Dashboard created--------------------- ");
    try{
      // await setTanks();
      // await setAreas();

      // this.oilColors = this.$store.state.codeMaps['OIL_COLOR'];
      // this.oilCodeMap = this.$store.state.codeMaps['OIL_CODE'];
      // this.areaCodes = this.$store.state.area['codes'];
      // this.tanks = this.$store.state.tanks['codes'];
      await sleep(2000);

      this.oilColors = this.$store.state.codeMaps['OIL_COLOR'];
      this.oilCodeMap = this.$store.state.codeMaps['OIL_CODE'];
      this.areaCodes = this.$store.state.area['codes'];
      this.tanks =  this.$store.state.tanks['codes'];

      // console.log("--- oilColors --->", this.oilColors );
      const tids = Object.keys(this.tanks)
      console.log("--- tank ids --->", tids );
      for( const tid of tids ){
        this.volumes[tid] = { dt: null, max: 0, real: 0, ovm: 0, ovc: 0, wv: 0, tvp: 0, avgTm: 0};
        this.tankState[tid] = {
          text:'미확인',
          variant: 'dark',
          pstsVariant: 'dark',
          iosts: '',
          tsts: {
            code:'0000',
            message:'',
            updatedAt: null
          },
          psts: {
            code:'0000',
            message:'',
            updatedAt: null
          }
        };
        this.events[tid] = { events: [], dt: new Date() };
        this.tankChartKey[tid] = random(0,10000);
      }

      console.log( 'areaCodes --->', this.areaCodes );

      await this.getOilWidget();

      await this.getTankChart();


/*
      console.log( 'this.$watch ------------->', this.$watch );
      Object.keys(this.tankState).map(k=>{
        console.log( 'register watch --- key=', k )
        // this.$watch( ()=>this.tankState[k].tsts, this.watchState, {deep: true});
        this.$watch(
          () => JSON.stringify(this.tankState[k].tsts),
          (newValue, oldValue) => {
            console.log( '%%%%%%%%%%%% watch---->', newValue, oldValue)
          }
        );
      });*/
      // console.log( 'this.$watch ------------->', this.$watch );

      await sleep(1000);



      this.socket = this.$store.state.socket;

      if( this.socket ) {
        this.socket.removeListener('tank-sts', this.tankStateHandler);
        this.socket.removeListener('event', this.eventAlarmHandler);
        this.socket.removeListener('inventory', this.inventoryHandler);
        this.socket.removeListener('disconnect');
        this.socket.removeListener('connect');
      }

      await sleep(1000);

      console.warn("[MONITOR] ############ register atgDataHandler #########");
      // this.socket.removeAllListeners('tank-sts');
      // this.socket.removeAllListeners('event');
      this.socket.on('tank-sts', this.tankStateHandler);
      this.socket.on('event', this.eventAlarmHandler);
      this.socket.on('inventory', this.inventoryHandler);

      this.socket.on('disconnect', ()=>{
        speech('ATG 서버와 통신이 유실 되었습니다.');
        modalWarn(this.$bvModal, 'ATG 서버와 통신이 유실 되었습니다.');
        // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
        // window.close();
      });

      this.socket.on('connect', ()=>{
        speech('ATG 서버와 통신이 연결 되었습니다.');
        modalSuccess(this.$bvModal, 'ATG 서버와 통신이 연결 되었습니다.');
      });

      await sleep(1000);

      console.warn( "[MONITOR] ############ socket object ------> is connecting? ", this.socket.connected );
      this.isReady = true;

    }catch(err){
      console.log(err);
    }
  },

  methods: {
    watchState(oldVal, newVal){
      console.log( '!!!!!!!!!!!! sts --->', oldVal, newVal );
    },
    progressVariant(v){
      if(!v) return 'dark';

      if(v<40) return 'warning';
      else if(v > 90) return 'danger';
      else return 'info';
    },

    gotoMonitor(arCode, tankId='') {

      let tid = tankId? tankId: '';
      let mode = tid? 'tank':'area';

      let query = `mode=${mode}&arCode=${arCode}&tid=${tid}`;
      console.log( '%%%%%%%%%%%%%%%% gotoMonitor ------------- store.state.user ---->', this.$store.state.user );
      let width=930;
      let winName = arCode;
      if(tid) {
        width = 514;
        winName = tid;
      }

      let route = this.$router.resolve({ path: `/monitoring?${query}`});
      const features = `left=0,top=0,width=${width},height=545,status=no,titlebar=no,location=no,menubar=no,toolbar=no`
      const newWin = window.open(route.href, `monitor_${winName}`, features );
      newWin.storedUser = localStorage.user;
      console.log(  'stored user --->', newWin.storedUser );

    },

    tankStateHandler(data){
      // console.log('tankStateHandler--------------', data );
      const {tid} = data;
      const {tnm}  = data;
      const tState = this.tankState[tid];
      // console.log( tnm, tid,'--- tank state --->',tState );
      if(!tState){
        console.warn( `[${tid}] ${tnm} --- TankState not found skip process, check area of tank` );
        return;
      }

      let volume = data.payload.volume;
      this.volumes[tid] = volume;
      // console.log( '############## volumes by tid ---------->', tid, this.volumes[tid] );

      const {sts} = data;
      const {psts} = data.payload;

      // console.log( tnm, tid,'--- tank sts --->',sts );




      tState.tsts = sts;
      tState.psts = psts;
      tState.sts = sts.io;

      tState.text = IoStsMap[sts.io];

      switch (sts.io) {
        case 'I':
          tState.variant = 'danger';
          break;
        case 'O':
          tState.variant = 'info';
          break;
        case 'N':
          tState.variant = 'success';
          break;
        case 'R':
          tState.variant = 'danger';
          break;
        case 'D':
          tState.variant = 'warning';
          break;
        default:
          tState.text = '미확인';
          tState.variant = 'secondary';
          break;
      }

      switch(psts.code){
        case 'PKT_LST':
          tState.pstsVariant = 'danger';
          break;
        case 'PKT_DN':
          tState.pstsVariant = 'warning';
          break;
        case 'NO_PKT':
          tState.pstsVariant = 'dark';
          break;
        case 'READY':
          tState.pstsVariant = 'info';
          break;
        case '0000':
          tState.pstsVariant = 'success';
          break
        default:
          tState.pstsVariant = 'dark';
          break;
      }


      if(sts.code!=='0000'){
        tState.text = sts.code;
        tState.variant = 'danger';

        if( sts.code.indexOf('ER_') > -1 ) {
          this.tankStateWatch.tid = tid;
          this.tankStateWatch.code = sts.code;
          this.tankStateWatch.message = sts.message;
        }
      }


      // this.tankStateWatch = tsts;


      // console.log( '############## tankState by tid ---------->', tid, this.tankState[tid] );

      this.progressKey = random(0,10000);

    },

    eventAlarmHandler(data){

      let tid = data.tid;
      let events = data.payload;

      this.events[tid] = {data: events, dt: data.dt };

      this.widgetKey = random(20000,30000);

      // console.log('************ eventAlarmHandler-------------', this.events[tid] );

    },

    async inventoryHandler(data){
      // console.log('^^^^^^^^^^^^ inventoryHandler ^^^^^^^^^^^^^^' );
      let tid = data.tid;
      await this.getTankChart(tid);

    },

    /**
     * 상단 유종별 그래프 데이터
     * @returns {Promise<void>}
     */
    async getOilWidget(){

      try{

        const r = await apiCall('get', `/api/graph/widget-oil/24`);
        if(r.code===200){
          this.oilStore = r.result.oilStore;
          this.oilHist = r.result.oilHist;
          this.oilCodes = Object.keys(this.oilStore);
        }

        console.log( 'oilCodes ------------>', this.oilCodes );
        console.log( 'oilHist ------------->', this.oilHist );


      }catch(err){
        console.log(err);
        await this.toastError(err);
      }finally{
        this.isOilReady = true;
      }
    },

    /**
     * 탱크별 데이터
     * @param tid
     * @returns {Promise<void>}
     */
    async getTankChart(tid=''){
      try{
        // console.log("GGGGGGGGGGGGGGGGGG getTankChart --- tid:", tid);

        let query='';
        if(tid) query= `tid=`+tid;
        const r = await apiCall('get', `/api/graph/tank/24?${query}`);
        if(r.code===200){
          if(tid) {
            this.tankHist[tid] = r.result.tankHist[tid];
            // console.log( 'getTankChart result', this.tankHist[tid] );
            this.tankChartKey[tid]++;
          }else{
            this.tankHist = r.result.tankHist;
          }

          // console.log( 'tankHist --------->', this.tankHist );
        }

      }catch(err){
        console.log(err);
        await this.toastError(err);
      }finally{
        this.isTankReady = true;
      }
    }
  },

  beforeDestroy(){
    console.log("Dashboard destroy---------------------->")
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) {
      this.socket.removeListener('tank-sts', this.tankStateHandler);
      this.socket.removeListener('event', this.eventAlarmHandler);
      this.socket.removeListener('inventory', this.inventoryHandler);
      this.socket.removeListener('disconnect');
      this.socket.removeListener('connect');
    }
  },



}
</script>
